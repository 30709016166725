<template>
  <div>
    <a
      @click="show = !show"
      :class="{ show: show }"
      class="list-group-item list-group-item-action"
    >
      {{ title }}
    </a>
    <div class="custom-collapse" :class="{ show: show }">
      <div class="card card-body border-0 px-4">
        <slot>
          Some placeholder content for the collapse component. This panel is
          hidden by default but revealed when the user activates the relevant
          trigger.
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListGroupItem",
  props: {
    title: {
      default: "List Group Item",
    },
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style lang="scss">
.custom-collapse {
  overflow: hidden;
  max-height: 0px;
  transition: all 0.25s ease-in-out;
  &.show {
    // position: absolute;
    max-height: 200px;
    .card {
      height: 100%;
    }
  }

  .card {
    height: 0%;
  }
}

.list-group-item {
  color: #3e9ea6;
}

.list-group .list-group-item:after {
  transition: all 0.2s;
}

// .list-group .list-group-item .show::before {
a.list-group-item.list-group-item-action.show::after {
  position: absolute;
  top: 0.9rem;
  right: 2rem;
  content: "";
  width: 20px;
  height: 20px;
  background: url(/img/arrow-right.261f8398.svg) left center no-repeat;
  background-size: 18px 15px;
  transform: rotate(90deg);
}

.section-two-col .row div {
  // min-height: 10px !important;
}
</style>
